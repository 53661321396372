@charset "utf-8";
/* CSS Document */

.flow > .swiper-container.swiper-container-initialized.swiper-container-horizontal {
    text-align: center;
    height: 474px;
}

.function > .swiper-container.swiper-container-initialized.swiper-container-horizontal {
    height: 650px;
}

.mobile > .swiper-container.swiper-container-initialized.swiper-container-horizontal {
    width: 100%;
    height: 734px;
    position: relative;
    top: -734px;
    z-index: 1;
}

.icon_back {
    height: 60px;
    width: 60px;
    position: absolute;
    top: 18px;
    left: 20px;
}

.QRscan {
    height: 60px;
    position: absolute;
    top: 18px;
    right: 20px;
}

.contents {
    background-size: cover;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 96px;
    padding-top: 0px;
}

.cont1 {
    width: 100%;
    height: 1136px;
    background: url(../images/bg_main_01.png) no-repeat center;
    background-size: cover;
}

.cont1 > div {
    transition: 1.5s;
}

.cont1_tit {
    font-size: 38px;
    line-height: 63px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 80px;
}

.tit_BSCP {
    font-family: "GmarketSansBold";
    font-size: 52px;
    color: #fff3ca;
    font-weight: 300;
    text-align: center;
    margin-bottom: 140px;
}

.cont1 h1 {
    text-align: center;
    padding-top: 120px;
    padding-bottom: 80px;
}

.btn_QRscan {
    text-align: center;
}

.btn_QRscan > span {
}

.cont_tit {
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    padding-top: 30px;
    margin-bottom: 60px;
    margin-left: 30px;
    background: url(../images/deco_line_02.png) left top no-repeat;
}

.cont2 {
    padding-top: 130px;
}

.flow_item {
    width: 532px;
    height: 474px;
    margin: 0 auto;
    position: relative;
}

.flow_01 {
    background: url(../images/img_flow_01.png) no-repeat;
}

.flow_02 {
    background: url(../images/img_flow_02.png) no-repeat;
}

.flow_03 {
    background: url(../images/img_flow_03.png) no-repeat;
}

.flow_04 {
    background: url(../images/img_flow_04.png) no-repeat;
}

.flow_05 {
    background: url(../images/img_flow_05.png) no-repeat;
}

.flow_06 {
    background: url(../images/img_flow_06.png) no-repeat;
}

.flow_txtbox {
    width: 100%;
    height: 188px;
    box-sizing: border-box;
    padding-top: 30px;
    text-align: center;
    position: absolute;
    bottom: 0;
}

.flow_txtbox h4 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
}

.flow_txtbox p {
    font-size: 24px;
    line-height: 32px;
}

/****서비스 흐름도에서 텍스트중에 굵은글씨, 붉은색으로 표시된 부분은 부분 클래스 .red 잡아주세요****/
.red {
    font-weight: 700;
    color: #ff3f3f;
}

.swipe {
    width: 480px;
    height: 10px;
    margin: 40px auto;
    background-color: #d4d4d4;
}

.swipe_6 {
    width: 80px;
    height: 10px;
    display: block;
    background-color: #ff3f3f;
    transition: 0.3s;
}

.cont3 {
    padding-top: 100px;
    margin-bottom: 130px;
}

.main_function_item {
    width: 100%;
    height: 650px;
    position: relative;
}

.function_01 {
    background: url(../images/img_function_01.png) no-repeat top;
}

.function_02 {
    background: url(../images/img_function_02.png) no-repeat top;
}

.function_03 {
    background: url(../images/img_function_03.png) no-repeat top;
}

.function_04 {
    background: url(../images/img_function_04.png) no-repeat top;
}

.function_txtbox {
    box-sizing: border-box;
    width: 656px;
    height: 240px;
    padding: 30px 30px 0;
    background-color: #2b7bd9;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    color: #ffffff;
    border-radius: 16px;
}

.function_txtbox h4 {
    width: 100%;
    font-size: 34px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ffffff;
}

.function_txtbox p {
    font-size: 24px;
    line-height: 42px;
    font-weight: 300;
}

.swipe_4 {
    width: 120px;
    height: 10px;
    display: block;
    background-color: #ff3f3f;
    transition: 0.3s;
}

.cont4 {
    padding-top: 80px;
    background: url(../images/bg_expectation.png) no-repeat bottom #226497;
    padding-bottom: 40px;
}

.cont4 .cont_tit {
    color: #ffffff;
    background: url(../images/deco_line_03.png) no-repeat left top;
}

.expectation_box {
    box-sizing: border-box;
    width: 656px;
    padding: 40px 30px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 16px;
    margin-bottom: 40px;
}

.expectation_box h4 {
    font-size: 34px;
    color: #1293c6;
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;
}

.expectation_box p {
    font-size: 24px;
    line-height: 42px;
}

.cont5 {
    padding-top: 80px;
    background: url(../images/bg_mobile.png) no-repeat center;
    padding-bottom: 40px;
}

.tit_sub {
    font-size: 28px;
    background-color: #1293c6;
    color: #ffffff;
    padding: 5px;
}

.cont5 .cont_tit {
    margin-bottom: 65px;
}

.mobile {
    text-align: center;
    width: 100%;
    height: 734px;
}

.img_mobile {
    transition: 0.5s;
    position: relative;
}

/**팝업**/
.dg_dark {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    z-index: 1000;
}

.popup_notice {
    position: fixed;
    z-index: 999999999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.notice {
    width: 656px;
    box-sizing: border-box;
    border: 1px solid #2d2d2d;
    border-radius: 16px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    font-family: "Noto Sans KR", sans-serif;
    font-size: 18px;
    line-height: 30px;
    background-color: #ffffff;
}

.notice h1 {
    font-size: 25px;
    line-height: 35px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 27px;
    color: #191919;
}

.notice h2 {
    font-size: 18px;
    font-weight: 700;
    text-align: right;
    color: #191919;
    margin-bottom: 30px;
}

.notice ol {
    margin-left: 12px;
}

.notice li {
    line-height: 30px;
}

.notice_close {
    position: absolute;
    z-index: 99999999999;
    bottom: -130px;
    left: 50%;
    transform: translateX(-50%);
}
