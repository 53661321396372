@charset "utf-8";
/* CSS Document */

#hide_back_button {
    display: none;
}

.no-sroll {
    height: 100%;
    min-height: 100%;
    overflow: hidden !important;
    touch-action: none !important;
}

.header_area {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
}

.header {
    width: 100%;
    height: 96px;
    line-height: 96px;
    /* margin-top: 20px; */
    text-align: center;
}

.header2 {
    width: 100%;
    height: 96px;
    line-height: 96px;
}

.header2 h1 {
    font-size: 30px;
    background-color: #ffffff;
    font-family: "BusanBada", sans-serif;
    font-weight: normal;
    height: 96px;
    line-height: 125px;
}

.header h1 {
    font-size: 30px;
    background-color: #ffffff;
    font-family: "BusanBada", sans-serif;
    font-weight: normal;
    height: 96px;
    line-height: 96px;
    line-height: 125px;
}

.menu {
    height: 56px;
    display: flex;
}

.menu li {
    float: left;
    flex: 1;
    text-align: center;
    font-size: 25px;
    line-height: 56px;
    background-color: #232323;
}

.menu li div {
    color: #d9d9d9;
}

.menu li.on {
    background-color: #0766d0;
}

.menu li.on div {
    color: #fff;
    font-weight: bold;
}

.contents {
    margin-top: 152px;
    padding-top: 40px;
    padding-left: 33.5px;
    padding-right: 33.5px;
    height: calc(100% - 96px);
}

.standard {
    text-align: right;
    font-size: 23px;
    color: #a4a4a4;
}

.freshness_standard {
    transition: 2s;
}

#basicinfo {
    margin-bottom: 120px;
}

#basicinfo h3 {
    margin-top: 40px;
    font-size: 42px;
    font-family: "Noto Sans KR";
    color: #191919;
    font-weight: bold;
}

.detail {
    margin-top: 30px !important;
    font-size: 26px !important;
    font-weight: 400 !important;
    font-family: "Noto Sans KR" !important;
    color: #191919 !important;
}

.decoline {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
}

.fresh {
    text-align: center !important;
    font-size: 40px !important;
    font-weight: 400 !important;
    font-family: "Noto Sans KR" !important;
    color: #191919 !important;
}

.fresh_graph {
    margin: 30px auto;
    width: 453px;
    height: 214px;
    font-size: 38px;
    padding-top: 150px;
    line-height: 55px;
    text-align: center;
    background: url(../images/graph_freshness.png) no-repeat top center;
}

.graph_data {
    font-size: 84px;
    font-weight: bold;
    color: #034ea2;
}

.verification {
    margin: 0 auto 20px;
    text-align: center;
}

.verification > .verification_img_area {
    position: absolute;
    width: 434px;
    height: 434px;
    top: 445px;
    z-index: 0;
    left: 145px;
    transition: 2s;
}

.average {
    width: 100%;
    padding: 5px 0;
    box-sizing: border-box;
    background-color: #eceff1;
    border-radius: 8px;
    margin-bottom: 30px;
}

.average ul {
    display: flex;
    justify-content: center;
}

.average ul li {
    float: left;
    margin: 0 8px;
}

.data_label {
}

.data {
    font-weight: 700;
}

.distribution_time {
    width: 100%;
    height: 175px;
    background: url(../images/distribution_time.png) no-repeat top center;
    position: relative;
    z-index: -1;
}

.distribution_data {
    width: 626px;
    height: 175px;
    margin: 0 auto;
    position: relative;
}

.time_start {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #455a64 !important;
    font-family: "Noto Sans KR" !important;
    font-size: 21px !important;
    font-weight: 400 !important;
}

.time_end {
    position: absolute;
    bottom: 0;
    right: 0;
    color: #455a64 !important;
    font-family: "Noto Sans KR" !important;
    font-size: 21px !important;
    font-weight: 400 !important;
}

.time_total {
    text-align: center;
    line-height: 43px;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.time_total_data {
    font-size: 38px;
    font-weight: 500;
    color: #005eec;
}

#distribution {
    margin-top: 100px;
}

.distribution_graph {
    z-index: -1;
    margin-bottom: 70px;
}

.y_axis_title {
    color: black;
    position: relative;
    font-size: 17px;
    top: -270px;
}

.y_axis_title > .humidity {
    font-size: 13px;
}

.y_axis_title > .temperature {
    position: absolute;
    left: 10px;
    font-size: 13px;
}

.verification > .check_button {
    position: relative;
}
.distribution_graph {
    position: relative;
    top: -250px;
}

.y_axis_right {
    position: relative;
    left: 35px;
    top: 220px;
}

.getXTimeAxisItem {
    text-align: center;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    font-family: "Noto Sans KR";
}

.time_axis_area {
    position: relative;
    top: -15px;
    left: -32px;
    padding-left: 32px;
}

.time_axis_bar {
    width: 655px;
    height: 12px;
    background-color: #f3f3f3;
    border-radius: 6px;
}

.data_accident_area > img {
    position: absolute;
    bottom: 40px;
    width: 32px;
    z-index: 1;
}

.distribution_graph_ex {
    position: relative;
    color: black;
    top: -280px;
}

.distribution_graph_ex > span {
}

.distribution_graph_ex > .humidity > .circle {
    background-color: #0079d7;
}

.distribution_graph_ex > .temperature > .circle {
    background-color: #d32f2f;
}

.distribution_graph_ex .circle {
    position: relative;
    top: 4px;
    width: 22px;
    height: 22px;
    border-radius: 11px;
    display: inline-block;
    margin-right: 7px;
}

.box_circulation_area {
    position: relative;
    top: -150px;
}

.box_circulation {
    height: 177px;
    margin-bottom: 16px;
    background: url(../images/icon_distribution.png) no-repeat left 60px top 40px #034ea2;
    border-radius: 16px;
    position: relative;
    color: #ffffff;
    z-index: -1;
}

.companyname {
    font-weight: bold;
}

.circulation_no {
    position: absolute;
    bottom: 35px;
    left: 60px;
}

.circulation_txt {
    position: absolute;
    top: 20px;
    left: 200px;
}

.circulation_txt p:first-child {
    margin-bottom: 20px;
    line-height: 30px;
}

.circulation_txt p:nth-child(2) {
    margin-bottom: 10px;
}

.box_distribution {
    height: 177px;
    margin-bottom: 16px;
    background: url(../images/icon_pd.png) no-repeat left 60px top 40px #ffffff;
    border: 1px dashed #034ea2;
    border-radius: 16px;
    position: relative;
    color: #191919;
    z-index: -1;
}

.distribution_no {
    position: absolute;
    bottom: 35px;
    left: 60px;
    color: #034ea2;
}

.distribution_txt {
    position: absolute;
    top: 20px;
    left: 200px;
}

.circulation_txt > .commaText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.distribution_txt > .commaText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.distribution_txt p:first-child {
    margin-bottom: 20px;
    line-height: 30px;
}

.distribution_txt p:nth-child(2) {
    margin-bottom: 10px;
}

.cont_accident {
    margin-bottom: 30px;
}

.cont_accident h4 {
    display: inline;
    height: 35px;
    line-height: 35px;
    font-size: 30px;
    margin-right: 20px;
}

.accident_label {
    display: inline-block;
    width: 80px;
    height: 35px;
    background-color: #f9472d;
    color: #ffffff;
    border-radius: 8px;
    line-height: 35px;
    text-align: center;
}

.cont_accident ul {
    margin-top: 20px;
}

.cont_accident ul li {
    margin-bottom: 12px;
}

.accident_location2 {
    background-color: #bcbcbc;
    margin-top: 30px;
}

.accident_location {
    height: 240px;
    background-color: #bcbcbc;
    /* margin-top: 30px;
    margin-bottom: 100px; */
}

.loading {
    width: 600px;
    height: 295px;
    background: #ffffff;
    border-radius: 8px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 12;
    text-align: center;
    font-size: 35px;
    color: #517297;
    font-weight: 700;
    box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.35);
}

.loading_icon {
    margin-top: 80px;
    margin-bottom: 30px;
}

/*loading_rotate*/
@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/*transform*/
.transform_rotate {
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.popup_result {
    width: 600px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 12000;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.35);
}

.popup_cont {
    width: 530px;
    padding: 0 35px;
    padding-top: 55px;
}

.popup_tit {
    font-size: 35px;
    line-height: 49px;
    font-weight: 500;
    margin-bottom: 45px;
}

.result_date {
    font-size: 32px;
    font-weight: 500;
    color: #0b69d3;
    margin-bottom: 15px;
}

.date_subtxt {
    color: #51667d;
    margin-bottom: 40px;
}

.result_box {
    height: 135px;
    padding-top: 40px;
    box-sizing: border-box;
    border-bottom: 1px dotted #dcdcdc;
    font-size: 27px;
    color: #2e2e2e;
}

.result_label {
    float: left;
}

.result_data {
    float: right;
    padding-top: 12px;
}

.data_number {
    font-size: 70px;
    color: #034ea2;
    font-weight: 500;
}

.color_red {
    color: #d70000 !important;
}

.popup_close {
    width: 100%;
    height: 90px;
    display: block;
    margin-top: 30px;
    border-top: 1px solid #d1d9e2;
    line-height: 90px;
    text-align: center;
    font-size: 25px;
    color: #191919;
    font-weight: 700;
    border-radius: 0 0 8px 8px;
}

.kakao_map {
    width: 100%;
    height: 240;
}
