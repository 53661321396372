@charset "utf-8";
/* CSS Document */

/*웹폰트 불러오기*/
@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);

/*부산바다*/
@font-face {
  font-family: 'BusanBada';
  font-style: normal;
  font-weight: 300;
  src: url('../font/BusanBada.ttf') format('truetype');
}

@import url(//fonts.googleapis.com/earlyaccess/kopubbatang.css);

.kopubbatang * {
  font-family: 'KoPub Batang', serif;
}

/*부산바다*/
@font-face {
  font-family: 'GmarketSansBold';
  font-style: normal;
  font-weight: 300;
  src: url('../font/GmarketSansBold.otf') format('truetype');
}

#show_readme_popup,
#home_hide_back_button,
#readme_hide_back_button,
#hide_scan_id {
  width: 0px;
  height: 0px;
  visibility: hidden;
}

/*공통코드*/
body,
div,
p,
ul,
li,
ol,
dl,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
fieldset {
  margin: 0;
  padding: 0;
}
html {
  -webkit-text-size-adjust: none; /*Chrome, Safari, newer versions of Opera*/

  -moz-text-size-adjust: none; /*Firefox*/

  -ms-text-size-adjust: none; /*Ie*/

  -o-text-size-adjust: none; /*old versions of Opera*/
  min-width: 720px;
  width: 100%;
  height: 100%;
}
body {
  width: 100%;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 23px;
  height: 100%;
  line-height: 1em;
  color: #121212;
  data-device: 'mobileView';
  box-sizing: border-box;
}

.body_rotate {
}
#root,
#wrap {
  width: 100%;
  height: 100%;
}
img {
  border: none;
  vertical-align: top;
}
select,
input {
  vertical-align: top;
}
fieldset {
  border: none;
}
legend {
  display: none;
}
hr {
  border: none;
  clear: both;
}
ul,
li,
ol {
  list-style: none;
}
table {
  border-collapse: collapse;
}
a {
  text-decoration: none;
  color: #121212;
}

/*float 속성 해지 및 부모태그 높이값을 자동으로 인식시키기 위한 가상의 클래스를 생성*/
.clearboth {
}
/*가상의 클래스를 생성하여 다음과 같은 처리를 해준다*/
.clearboth:after {
  content: '';
  display: block;
  clear: both;
}
/*사용법:float을 적용할 형제들의 엄마태그에 가상의 클래스 .clearboth 적용*/

/* 
/


/*/
/* LoadingProcess */
@import url(https://fonts.googleapis.com/css?family=Roboto:100);

/* body {
  margin-top: 100px;
  background-color: #137b85;
  color: #fff;
  text-align: center;
}

h1 {
  font: 2em 'Roboto', sans-serif;
  margin-bottom: 40px;
} */

.loadingProcess {
  border: 0;
  width: 80px;
  padding: 0px;
  margin-top: 90px;
  /* margin-left: calc(50% - 40px);
  margin-left: -webkit-calc(50% - 40px);
  margin-left: -moz-calc(50% - 40px); */
  /* background: #005bdd; */
}

.spin {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  border: dashed 5px #005bdd;
  -webkit-animation-name: spin;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
