@charset "utf-8";
/* CSS Document */

/*contents*/

#cont_area {
    padding: 0 32px;
    margin-bottom: 40px;
}

#cont_area .contents {
    padding-bottom: 130px;
    border-bottom: 2px solid #dcdcdc;
}

.cont_tit2 {
    margin-top: 50px;
    padding-bottom: 30px;
    margin-bottom: 60px;
    border-bottom: 1px solid #dcdcdc;
}

.cont_tit2 h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
}

.sub_tit2 {
    font-weight: 400;
    font-size: 30px;
}

.contents p {
    font-size: 24px;
    font-weight: 300;
    line-height: 43px;
    color: #505050;
}

.contents ul {
    font-size: 24px;
    font-weight: 300;
    line-height: 43px;
    color: #505050;
}

.contents ol {
    margin-left: 25px;
}

.point_txt {
    font-weight: 700 !important;
    color: #005eec !important;
}

.weight_bold {
    font-weight: 700 !important;
}

/*테이블*/
.table_fresh {
    width: 100%;
    border-top: 2px solid #191919;
    border-bottom: 2px solid #191919;
    font-size: 22px;
}

.table_th {
    border-top: 2px solid #191919;
    border-bottom: 1px solid #bdbdbd;
}

.table_fresh tr {
    height: 60px;
}

.table_fresh td {
    text-align: center;
    font-weight: 300;
}

/****하단페이지이동버튼****/

.pagebtn_area {
    margin-top: 30px;
}

.pagelink {
    width: 100%;
    height: 90px;
    border: 1px solid #dcdcdc;
    border-radius: 16px;
    display: block;
    font-weight: 700;
    text-align: center;
    line-height: 90px;
    margin-bottom: 16px;
}

.pagename::after {
    width: 13px;
    height: 22px;
    display: inline-block;
    content: "";
    background: url(../images/icon_arrow.png) no-repeat center;
    background-position: -6px 5px;
    vertical-align: 0;
    margin-left: 16px;
}
