@charset "utf-8";
/* CSS Document */

/*contents123*/

.cont_area{
	padding:50px 32px;
}

.bg_gray{
	background-color:#f6f8fa;
}


.cont_tit123{
	margin-bottom:40px;
}

.cont_tit123 h1{
	display:inline;
	font-weight:700;
	font-size:40px;
	background:url(../images/deco_line_blue.png) repeat-x bottom;
}

.cont_tit2_123 h1{
	font-weight:700;
	font-size:40px;
	margin-bottom:30px;
}

.sub_tit{
	font-weight:400;
	font-size:30px;
}

.contents123 p{
	font-size:24px;
	font-weight:300;
	line-height:43px;
	color:#505050;
}

.contents123 ul{
	font-size:24px;
	font-weight:300;
	line-height:43px;
	color:#505050;
}

.contents123 ol{
	margin-left:25px;
}

.point_txt123{
	font-weight:700 !important;
	color:#005eec !important;
}

.weight_bold{
	font-weight:700 !important;
}


/*테이블*/
.table_fresh{
	width:100%;
	border-top:2px solid #191919;
	border-bottom:2px solid #191919;
	font-size:22px;
}

.table_th{
	border-top:2px solid #191919;
	border-bottom:1px solid #bdbdbd;
}

.table_fresh tr{
	height:60px;
}

.table_fresh td{
	text-align:center;
	font-weight:300;
}

/*시설및장비*/
.cont_img{
	margin-bottom:30px;
}


/*참여기업*/
.company{
	margin-bottom:70px;
}

.company:last-child{
	margin-bottom:0;
}

.company_img{
	text-align:center;
	margin-bottom:20px;
}

.company_name{
	font-weight:300;
	color:#505050;
	font-size:24px;
	line-height:43px;
	text-align: center;
	margin-bottom:20px;
}

.company_info{
	width:100%;
	padding:25px 0;
	text-align: center;
	font-weight:300;
	font-size:24px;
	line-height:43px;
	border-radius:16px;
	background-color:#f4f4f4;
}
